<template>
  <div class="section" data-section="under">
    <div class="footer-switcher" @click="toggle_footer" data-toggle-footer>
      <div class="hint__btn hint__btn_cursor_pointer">
        <div class="hint__arrow footer-switcher__arrow"></div>
      </div>
    </div>
    <div class="box">
      <div class="logotype logotype_small">
        <a href="/" class="logo" title="area-game">
          <img src="@/assets/images/logo/logo.webp" alt="area-game" />
        </a>
      </div>
      <div class="container">
        <div class="under">
          <div class="under__ps ps">
            <div class="ps__item">
              <img
                src="@/assets/images/ps/paypal.png"
                alt="paypal"
                class="ps__img"
              />
            </div>
            <div class="ps__item">
              <img src="@/assets/images/ps/g2a.png" alt="g2a" class="ps__img" />
            </div>
            <div class="ps__item">
              <img
                src="@/assets/images/ps/paymentwall.png"
                alt="paymentwall"
                class="ps__img"
              />
            </div>
            <div class="ps__item">
              <img
                src="@/assets/images/ps/freekassa.png"
                alt="freekassa"
                class="ps__img"
              />
            </div>
            <div class="ps__item">
              <img
                src="@/assets/images/ps/payop.png"
                alt="payop"
                class="ps__img"
              />
            </div>
          </div>
        </div>
        <div class="under" style="justify-content: center">
          SIA "La 2 Dream"
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterPayments",
  methods: {
    toggle_footer() {
      this.$store.dispatch("toggle_footer");
    },
  },
};
</script>

<style></style>
